import './Finished.scss';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FinishStatus, IAppContext } from '../types';
import { AppContext } from '../context/Context';
import InfoIcon from '@mui/icons-material/Info';
import { formatValue } from '../helpers/Utils';
import ProgressLoading from '../components/common/ProgressLoading';
import {
  requestPreInspectionFinish,
  shareQuoteLocation,
} from '../services/preInspect.service';
import ContinueButton from '../components/carousel/ContinueButton';
import {
  BRAND,
  completeImg,
  finishIcon,
} from '../static/constants/brands/brand';
import { ActionType } from '../context/actions';
import { Company } from '../interfaces/brand';
import i18n from '../utils/i18n';

const endTextOptions: { [key: string]: string } = {
  IS_CORREDOR: 'Su corredor se pondrá en contacto con ustedes.',
  IS_AUTOEXPRESS: 'Su póliza será enviada en breve.',
  bcr: 'Su corredor de seguros le estará contactando para finalizar el proceso de aseguramiento de su vehículo.',
  DEFAULT:
    'Uno de nuestros agentes se estará comunicando contigo para los siguientes pasos.',
};

const sloganText: { [key: string]: string } = {
  bcr: 'Lo hacemos más fácil.',
};

const PROCESS_MESSAGES = [
  'Un momento por favor, estamos procesando la información.',
  'Esto puede demorar un poco, un momento por favor.',
  'Estamos procesando la información, pronto continuaremos con la inspección.',
];

const PROCESS_RETRY_MESSAGES = [
  'Analizando información enviada...',
  'Analizando fotografías enviadas...',
  'Revisando la integridad de los datos...',
  'Esto puede demorar un poco, un momento por favor.',
  'Seguimos procesando la información, pronto continuaremos con la inspección.',
  'Esto puede demorar un poco, un momento por favor.',
  'Esto puede demorar un poco, un momento por favor.',
  'Terminando el proceso de análisis, un momento por favor.',
];

const PROCESS_MESSAGE_DURATION = 75000;
const WAITING_PROCESS_MESSAGE_DURATION = 99000;

const FinishedScreen: FunctionComponent = (): JSX.Element => {
  const {
    state: { preInspectionModel, alertData },
    dispatch,
  } = useContext(AppContext) as IAppContext;
  const [endText, setEndText] = useState(
    endTextOptions[BRAND] || endTextOptions.DEFAULT,
  );
  const [loading, setLoading] = useState(false);
  const [initRequest, setInitRequest] = useState(true);
  const [requestFinishSuccess, setRequestFinishSuccess] = useState(false);
  const [resend, setResend] = useState(false);
  const [resultRequest, setResultRequest] = useState('');
  const [timeStampInit, setTimeStampInit] = useState(new Date());
  const [waitingProcessIsActive, setWaitingProcessIsActive] = useState(false);

  const hasURLToRedirect =
    preInspectionModel.preRedirectURL &&
    preInspectionModel.preRedirectURL.length > 0;

  const getCurrentTime = (): number => {
    const currentDate = new Date();
    const data = currentDate.getTime() - timeStampInit.getTime();
    const result: any = Number(data / 1000);

    return result;
  };

  const triggerExpireProcess = () => {
    setRequestFinishSuccess(false);
    setLoading(false);
    setEndText(
      'Estamos procesando su información, en unos minutos recibirá un correo con el resultado de su inspección y las instrucciones a seguir.',
    );
  };

  const renderProcessResult = (resultMessage: string): void => {
    setRequestFinishSuccess(true);
    setLoading(false);
    setResultRequest(resultMessage);
    dispatch({
      type: ActionType.SET_FINISH_STATUS,
      payload: resultMessage.trim().toUpperCase(),
    });
  };

  const finishProcess = async () => {
    setLoading(true);
    setResend(false);
    // Request a Finish result
    let resultMessage = await requestPreInspectionFinish(
      preInspectionModel.preInspectionId,
    );

    if (!waitingProcessIsActive) {
      resultMessage =
        getCurrentTime() > PROCESS_MESSAGE_DURATION / 1000
          ? 'EXPIRE'
          : resultMessage;
    }

    if (resultMessage !== undefined && resultMessage === 'FINISHED') {
      setLoading(false);
    } else if (resultMessage !== undefined && resultMessage === 'WAITING') {
      // Recursive for wait response
      await finishProcess();
    } else if (
      resultMessage !== undefined &&
      resultMessage === 'BACKGROUND_PROCESS'
    ) {
      // Recursive for wait response
      setWaitingProcessIsActive(true);
      setTimeout(async () => {
        renderProcessResult('SATISFACTORIO');
      }, 40000);
    } else if (resultMessage === undefined || resultMessage === 'EXPIRE') {
      triggerExpireProcess();
    } else if (
      resultMessage !== undefined &&
      (resultMessage.indexOf('SATISFACTORIO') > -1 ||
        resultMessage.indexOf('NO SATISFACTORIO') > -1)
    ) {
      renderProcessResult(resultMessage);
    } else if (
      resultMessage !== undefined &&
      resultMessage.indexOf('ERROR') > -1
    ) {
      setRequestFinishSuccess(false);
      setLoading(false);
      setResend(true);
      setResultRequest(resultMessage);
    }
  };

  const triggerToRedirect = () => {
    window.location.href = preInspectionModel.preRedirectURL;
  };

  const getTicketConsecutive = (): string => {
    const ticketConsecutive =
      preInspectionModel !== null &&
      String(preInspectionModel?.preInspectionId).length > 0
        ? formatValue(preInspectionModel?.preInspectionId, '000000', '0', true)
        : '000000';

    return '#' + ticketConsecutive;
  };

  const shareLocation = async (): Promise<void> => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          dispatch({
            type: ActionType.SET_PREINSPECTION_GEOLOCATION,
            payload: {
              lat: String(position.coords.latitude),
              lng: String(position.coords.longitude),
            },
          });

          // Send location to the server
          await shareQuoteLocation(
            preInspectionModel.preInspectionId,
            position.coords.latitude,
            position.coords.longitude,
            position.coords.accuracy,
            'FINISH',
          );
        },
        () => {
          console.warn('Can not get the current location.');
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0, // Avoid using cached locations
        },
      );
    }
  };

  const headerMessage = (resultMessage: string): JSX.Element => {
    return (
      <>
        {Company.SURA === BRAND ? (
          <>
            <h2>
              {i18n.get(
                'finished.headerMessage.label',
                'Inspección Finalizada',
              )}
            </h2>
          </>
        ) : Company.INS === BRAND ? (
          <>
            <h2>
              {i18n.get(
                'finished.headerMessage.label',
                'Inspección Finalizada',
              )}
            </h2>
          </>
        ) : requestFinishSuccess &&
          resultMessage.trim().toUpperCase() === FinishStatus.SATISFACTORIO ? (
          <>
            <h2>
              {i18n.get(
                'finished.headerMessage.SATISFACTORIO',
                'Hemos tomado nota del estado actual de su Vehículo',
              )}
            </h2>
            <img
              src={finishIcon[BRAND]}
              className="theme-svg-icon"
              width={35}
              height={35}
            />
          </>
        ) : resultMessage.trim().toUpperCase() ===
          FinishStatus.NO_SATISFACTORIO ? (
          <>
            <h2>
              {i18n.get(
                'finished.headerMessage.NO_SATISFACTORIO',
                'Hemos tomado nota del estado actual de su Vehículo',
              )}
            </h2>
            <InfoIcon className="icon-info theme-svg-icon" />
          </>
        ) : (
          <h2>
            {i18n.get('finished.headerMessage.label', 'Inspección Finalizada')}
          </h2>
        )}
      </>
    );
  };

  const imageMessage = (resultMessage: string): JSX.Element => {
    return (
      <>
        {resultMessage.trim().toUpperCase() === FinishStatus.SATISFACTORIO ||
        BRAND === Company.BCR ? (
          <>
            <img
              src={completeImg[BRAND].source}
              className="theme-svg-icon"
              width={completeImg[BRAND].width}
              height={completeImg[BRAND].height}
            />
            <span>{sloganText[BRAND] || ''}</span>
          </>
        ) : (
          <p className="no-image"></p>
        )}
      </>
    );
  };

  const bodyMessage = (resultMessage: string): JSX.Element => {
    return (
      <>
        {BRAND === Company.IS ? (
          <>
            {resend ? (
              <>
                <p className="finished__info">
                  <label>
                    <i
                      dangerouslySetInnerHTML={{
                        __html: i18n.get(
                          'finished.bodyMessage.label',
                          'Si el problema persiste favor contáctenos...',
                        ),
                      }}
                    ></i>
                  </label>
                </p>
                <ContinueButton onClick={finishProcess}>
                  {i18n.get(
                    'finished.bodyMessage.continueButton',
                    'Reenviar Información',
                  )}
                </ContinueButton>
              </>
            ) : (
              <>
                {resultMessage.indexOf('ERROR') > -1 ? (
                  <p>
                    {i18n.get(
                      'finished.bodyMessage.error',
                      'Se detectaron inconvenientes en la conexión...',
                    )}
                  </p>
                ) : requestFinishSuccess ? (
                  resultMessage.trim().toUpperCase() ===
                    FinishStatus.SATISFACTORIO && alertData?.length === 0 ? (
                    <>
                      <br />
                      <p>
                        {i18n.get(
                          'finished.bodyMessage.success',
                          'Ya puedes continuar con la compra de tu póliza de seguro para el:',
                        )}
                      </p>
                      <br />
                      <p>
                        <b>
                          {preInspectionModel.preVehicleBrand}{' '}
                          {preInspectionModel.preVehicleModel}{' '}
                          {preInspectionModel.preVehicleYear}{' '}
                          {preInspectionModel.preVehicleColor}
                        </b>
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: i18n.get(
                            'finished.bodyMessage.message',
                            'Puedes continuar tu proceso...',
                            [getTicketConsecutive()],
                          ),
                        }}
                      ></p>
                    </>
                  )
                ) : (
                  <p>{endTextOptions['DEFAULT']}</p>
                )}
              </>
            )}
          </>
        ) : BRAND === Company.SURA ? (
          <>
            <p id="pre-id">Inspección {getTicketConsecutive()}</p>
            <br />
            {requestFinishSuccess &&
              resultMessage.trim().toUpperCase() ===
                FinishStatus.SATISFACTORIO && (
                <p>
                  {i18n.get(
                    'finished.bodyMessage.result.success',
                    'Recuerda guardar este número de inspección para tú seguimiento.',
                  )}
                </p>
              )}
            {requestFinishSuccess &&
              resultMessage.trim().toUpperCase() ===
                FinishStatus.NO_SATISFACTORIO && (
                <p>
                  {i18n.get(
                    'finished.bodyMessage.result.no-success',
                    'Inspección en análisis. Recuerda guardar este número de inspección para tú seguimiento.',
                  )}
                </p>
              )}
          </>
        ) : BRAND === Company.INS ? (
          <>
            <p id="pre-id">Inspección {getTicketConsecutive()}</p>
            <br />
            {requestFinishSuccess &&
              resultMessage.trim().toUpperCase() ===
                FinishStatus.SATISFACTORIO && (
                <p>
                  {i18n.get(
                    'finished.bodyMessage.result.success',
                    'Recuerda guardar este número de inspección para tú seguimiento.',
                  )}
                </p>
              )}
            {requestFinishSuccess &&
              resultMessage.trim().toUpperCase() ===
                FinishStatus.NO_SATISFACTORIO && (
                <p>
                  {i18n.get(
                    'finished.bodyMessage.result.no-success',
                    'Inspección en análisis. Recuerda guardar este número de inspección para tú seguimiento.',
                  )}
                </p>
              )}
          </>
        ) : (
          <p>{endText}</p>
        )}
      </>
    );
  };

  const footerMessage = (resultMessage: string): JSX.Element => {
    return (
      <>
        {requestFinishSuccess &&
          resultMessage.trim().toUpperCase() === FinishStatus.SATISFACTORIO &&
          hasURLToRedirect && (
            <ContinueButton onClick={triggerToRedirect}>
              Continuar
            </ContinueButton>
          )}
        {requestFinishSuccess &&
          resultMessage.trim().toUpperCase() ===
            FinishStatus.NO_SATISFACTORIO &&
          BRAND !== Company.BCR && (
            <div className="btnContact">
              <ContinueButton>Contáctanos</ContinueButton>
            </div>
          )}
      </>
    );
  };

  useEffect(() => {
    if (initRequest) {
      setTimeStampInit(new Date());
      setInitRequest(false);
      (async (): Promise<void> => {
        await finishProcess();
        await shareLocation();
      })();
    }
  }, []);

  useEffect(() => {
    if (loading && getCurrentTime() > PROCESS_MESSAGE_DURATION) {
      triggerExpireProcess();
    }
  });

  return (
    <div className="finished">
      {loading && (
        <ProgressLoading
          messages={
            waitingProcessIsActive ? PROCESS_RETRY_MESSAGES : PROCESS_MESSAGES
          }
          duration={
            waitingProcessIsActive
              ? WAITING_PROCESS_MESSAGE_DURATION
              : PROCESS_MESSAGE_DURATION
          }
        />
      )}
      {headerMessage(resultRequest)}

      <div className="finished__info">{bodyMessage(resultRequest)}</div>
      <div className="finished__image">{imageMessage(resultRequest)}</div>
      <div className="finished__footer">{footerMessage(resultRequest)}</div>
    </div>
  );
};

export default FinishedScreen;
